import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { bgBG as coreBgBG } from '@mui/material/locale';

const PRIMARY_HEX = '#fcbf00';

export const interFont = [
    'Inter',
    '"Helvetica Neue"',
    '-apple-system',
    'Arial',
    'sans-serif'
].join(',');

const theme = createTheme(
    {
        palette: {
            mode: 'light',
            primary: {
                main: PRIMARY_HEX,
                light: '#fff',
                contrastText: '#fff'
            },
            secondary: {
                main: '#e7e7e7',
                light: '#ebf6ff'
            },
            background: {
                default: 'rgb(235, 237, 242)'
            },
            contrastThreshold: 3,
            tonalOffset: 0.2
        },
        typography: {
            fontFamily: interFont,
            button: {
                textTransform: 'none'
            },
            h1: {
                fontWeight: 600
            },
            h2: {
                fontWeight: 600
            },
            h3: {
                fontWeight: 600
            },
            h4: {
                fontWeight: 600
            },
            h5: {
                fontWeight: 600
            },
            h6: {
                fontWeight: 600
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        backgroundColor: 'rgb(235, 237, 242)',
                        body: {
                            backgroundColor: 'rgb(235, 237, 242)'
                        }
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        '&.MuiButton-containedInfo': {
                            color: 'rgba(0, 0, 0, 0.87)',
                            backgroundColor: '#fff5d9',
                            '&:hover': {
                                backgroundColor: '#E9E0C8'
                            }
                        },
                        '&.MuiButton-outlinedSecondary': {
                            color: 'rgba(0, 0, 0, 0.87)',
                            backgroundColor: 'transparent',
                            borderColor: 'rgba(0, 0, 0, 0.87)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.20)'
                            }
                        }
                    }
                }
            },
            MuiCardHeader: {
                styleOverrides: {
                    title: {
                        fontSize: '1rem'
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    button: {
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        '& .MuiPaper-outlined': {
                            border: '1px solid rgba(0, 0, 0, 0.42)'
                        }
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        minHeight: 60
                    }
                }
            },
            MuiDrawer: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'red'
                    }
                }
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        opacity: 0.1,
                        transform: 'translateY(10px) !important'
                    }
                }
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(0, 0, 0, 0.42)'
                    }
                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        boxShadow:
                            'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.45) 0px 1px 3px -1px'
                    }
                }
            },

            MuiStepContent: {
                styleOverrides: {
                    root: {
                        paddingTop: '20px'
                    }
                }
            },
            MuiInputLabel: {
                defaultProps: { shrink: true }
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(256, 256, 256, 0.05)',
                            '&:hover': {
                                backgroundColor: 'rgba(256, 256, 256, 0.05)'
                            }
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(256, 256, 256, 0.05)'
                        }
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: 'black'
                    }
                }
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        boxShadow:
                            'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px,rgba(0, 0, 0, 0.05) 0px 1px 3px -1px'
                    }
                }
            }
        },
        mixins: {
            MuiDataGrid: {
                pinnedBackground: '#E5E5E5',
                containerBackground: '#fff'
            }
        }
    },
    coreBgBG
);

theme.shadows[1] =
    'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px';

type Theme = typeof theme;

/* tslint:disable:no-empty-interface */
declare module 'styled-components' {
    export interface DefaultTheme extends Theme {}
}
/* tslint:enable:no-empty-interface */

export { theme, Theme };
